import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import classes from "./BackgroundImage.module.css";  // Ensure this path is correct

const BackgroundImage = ({ image, isVisible = false }) => {
    const [isScrolled, setIsScrolled] = useState(false);

    // Function to detect the scroll position and trigger class toggle
    useEffect(() => {
        const handleScroll = () => {
            // Toggle isScrolled based on scroll position
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <AnimatePresence>
            {isVisible && (
                <motion.img
                    key={image}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, scale: 1, rotate: 0 }}
                    transition={{ type: "spring", duration: 5 }}
                    className={`${classes.background} ${isScrolled ? classes.scrolled : ""}`}
                    src={image}
                    layoutId="main-background-image"
                />
            )}
        </AnimatePresence>
    );
};

export default BackgroundImage;
