import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./LanguageSwitcher.module.css";
import iconContact from "../../images/icon_contact.png";
import React from 'react';


export default function LanguageSwitcher({ className }) {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Extract the current language from the URL
    const currentLanguage = location.pathname.split('/')[1] || 'de';

    // Set the language if it exists in the URL and is different from the i18n language
    if (currentLanguage && currentLanguage !== i18n.language) {
        i18n.changeLanguage(currentLanguage);
    }

    // Function to change the language and update the URL
    const handleLanguageChange = (e) => {
        const newLanguage = e.target.value;
        i18n.changeLanguage(newLanguage);

        // Construct the new path by replacing the old language in the URL
        let newPath = location.pathname;

        // If the URL starts with the current language, replace it with the new language
        if (newPath.startsWith(`/${currentLanguage}`)) {
            newPath = newPath.replace(`/${currentLanguage}`, `/${newLanguage}`);
        } else {
            // If no language is in the URL, just prepend the new language
            newPath = `/${newLanguage}${newPath}`;
        }

        // Navigate to the new language path
        navigate(newPath);
    };

    return (
        <div className={`${classes.selectWrapper} ${className}`}>
            <a href="mailto:Isabelle.Schmartz@men.lu"><img className={classes.icon} src={iconContact} /></a>
            <select className={classes.select} value={i18n.language} onChange={handleLanguageChange}>
                <option value="en">EN</option>
                <option value="fr">FR</option>
                <option value="de">DE</option>
            </select>
        </div>
    );
}
