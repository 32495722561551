import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './css/App.css';
import Loading from './components/Animations/Loading.jsx';
import RootLayout from './components/RootLayout.jsx';
import ErrorPage from './pages/Error.jsx';
import { Suspense, lazy } from 'react';
import BackgroundSwitcher from './components/BackgroundImage/BackgroundSwitcher.jsx';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { queryClient } from './util/http.js';
import PhotoPopup from './components/popups/PhotoPopup.jsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const HomePage = lazy(() => import('./pages/Home'));
const ExhibitionPage = lazy(() => import('./pages/Exhibition'));
const LibraryPage = lazy(() => import('./pages/Library'));
const MaterialPage = lazy(() => import('./pages/Material'));


function App() {
  const { i18n } = useTranslation(["General"]);
  const currentLanguage = i18n.language;

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {
          index: true,
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage='home' />
              <HomePage />
            </Suspense>
        }
      ]
    },
    {
      // Dynamic language segment in the path
      path: '/:lang', // ':lang' will be a dynamic parameter for language
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      error: (error) => <ErrorPage error={error} />, // Pass the error object to ErrorPage
      children: [
        {
          index: true,
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage='home' />
              <HomePage />
            </Suspense>
        },
        {
          path: 'exhibition',
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage='exhibition' />
              <ExhibitionPage />
            </Suspense>,
          loader: () => import('./pages/Exhibition').then(module => module.loader(currentLanguage)),
          children: [
            {
              path: ':photo_id',
              element:
                <Suspense fallback={<Loading />}>
                  <PhotoPopup />
                </Suspense>
            }
          ]
        },
        {
          path: 'library',
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage='library' />
              <LibraryPage />
            </Suspense>,
          loader: () => import('./pages/Library').then(module => module.loader())
        },
        {
          path: 'material',
          element:
            <Suspense fallback={<Loading />}>
              <BackgroundSwitcher activePage='material' />
              <MaterialPage />
            </Suspense>,
          loader: () => import('./pages/Material').then(module => module.loader())
        }
      ]
    }
  ]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
