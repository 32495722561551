import { useTranslation } from "react-i18next";
import Header from '../components/Header/Header.jsx';
import React from 'react';

export default function ErrorPage({ error }) {
    const { t } = useTranslation(["General"]);
    return (
        <>

            <Header />
            <h1>{t('An Error occured')}</h1>
            <p>{error?.message || "An unexpected error occurred."}</p>

        </>
    );
}