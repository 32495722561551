import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      // translation file path
      loadPath: "/i18n/{{ns}}/{{lng}}.json",
    },
    fallbackLng: "de",
    // please disable debug in production
    // ns means namespace. It is used to group translations into different files.
    // can have multiple namespaces, in case you want to divide a huge
    // translation into smaller pieces and load them on demand
    ns: ["General"],
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      useSuspense: true, // Enable Suspense mode
    },
  });

export default i18n;