//import TabBar from './components/Misc/TabBar.jsx';
import classes from './TabBar.module.css';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import React from 'react';

export default function TabBar({ menuItems, onSetPage, isActive, className }) {


    return (
        <ul className={`${classes.tabbarmenu} ${className}`}>
            {menuItems.map((item, index) => <li key={index}>
                <NavLink
                    className={({ isActive }) => isActive ? classes.active : undefined}
                    to={item.path}>
                    {({ isActive }) => (
                        <>
                            {item.label}
                            {isActive && (
                                <motion.div
                                    layoutId='tab-indicator'
                                    className={classes.activeTabIndicator}>
                                </motion.div>
                            )}
                        </>
                    )}
                </NavLink>
            </li>)}
        </ul>

    );
}