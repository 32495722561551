import Lottie from 'react-lottie-player';
import animationData from '../../data/gCnBAlCT49.json'; // Update with the correct path to your JSON file
import classes from './Loading.module.css';
import React from 'react';

export default function Loading() {
    return (
        <div className={classes.loadingWrapper}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'spring', duration: 1 }}>
            <Lottie
                animationData={animationData}
                loop
                play
            />
        </div>
    );
}