import { useEffect, useRef, useState } from 'react';
import classes from './PhotoPopup.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import closeIcon from '../../images/close.png';
import { fetchData } from '../../util/http';
import Loading from '../Animations/Loading';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

export default function PhotoPopup({ closePopup }) {

    const { photo_id } = useParams();
    const { i18n } = useTranslation(["General"]);
    const currentLanguage = i18n.language;
    const currentLanguageURLString = i18n.language === 'en' ? '' : `${i18n.language}/`;

    const [isVisible, setIsVisible] = useState(true);
    const [hashValue, setHashValue] = useState('');
    const contentWrapperRef = useRef(null); // Create a ref for the content wrapper


    const { data, isPending, isError, error } = useQuery({
        queryKey: ['photo', photo_id, currentLanguage],
        queryFn: () => fetchData('https://xn--wsten-kvaa.script.lu/admin/' + currentLanguageURLString + 'jsonapi/node/photo/' + photo_id + '?include=field_photo')
    });
    const navigate = useNavigate();

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => navigate('../'), 200); // delay navigation to allow exit animation
    };
    useEffect(() => {
        // Function to handle scrolling if hash is "qr"
        const handleInitialHashCheck = () => {
            const initialHashValue = window.location.hash.substring(1);
            setHashValue(initialHashValue);

            if (initialHashValue === 'qr' && contentWrapperRef.current) {
                contentWrapperRef.current.scrollTo({
                    top: window.outerHeight,
                    behavior: 'smooth',
                });
            }
        };

        // Execute on component mount
        handleInitialHashCheck();

        // Optional: Listen for hash changes (if you also want to handle changes after initial load)
        const handleHashChange = () => {
            const newHashValue = window.location.hash.substring(1);
            setHashValue(newHashValue);

            if (newHashValue === 'qr' && contentWrapperRef.current) {
                contentWrapperRef.current.scrollTo({
                    top: window.outerHeight,
                    behavior: 'smooth',
                });
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [data]);
    let content;

    if (isPending) {
        content = <Loading />;
    }

    if (isError) {
        content = <div>Error: {error.message}</div>;
    }

    if (data) {
        content = (<>
            <motion.img
                src={`https://xn--wsten-kvaa.script.lu/admin/sites/default/files/styles/big/public/${data.included[0].attributes.uri.value.replace('public://', '')}`}
                alt='' />

            <div className={classes.contentWrapper} >
                <div className={classes.content}>
                    <div className={classes.header}>
                        <h2>{data.data.attributes.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: data.data.attributes.body.value }} />

                    </div>
                    {data.data.attributes.field_qr_content && <div className={classes.qrcontent} dangerouslySetInnerHTML={{ __html: data.data.attributes.field_qr_content.value }} />}
                </div>
            </div>
        </>);
    }


    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    className={classes.dialog}
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 30 }}
                    transition={{ type: 'spring', duration: 0.5 }}
                    ref={contentWrapperRef}
                >
                    <button onClick={handleClose}>
                        <img src={closeIcon} alt="close" />
                    </button>
                    {content}
                    <LanguageSwitcher className={classes.languageSwitcher} />
                </motion.div>
            )}
        </AnimatePresence>
    );
}