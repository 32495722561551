import classes from "./GovBarBlock.module.css";
import React from 'react';

export default function GovBarBlock() {

    return (
        <>
            <div id="govbar" className={classes.govbar}>
                <a href="http://gouvernement.lu" target="_blank" className={classes.govbarlogo} title="Government of the Grand Duchy of Luxembourg (new window)">
                    <img src="//cdn.public.lu/skizz/govbar/logos/gov-light.png" srcSet="//cdn.public.lu/skizz/govbar/logos/gov-light.png 1x, //cdn.public.lu/skizz/govbar/logos/gov-light-2x.png 2x" alt="Government of the Grand Duchy of Luxembourg" />
                </a>
                <ul className={classes.govbarlinks}>
                    <li><a className={classes.govbarlink} href="http://luxembourg.lu" target="_blank" title="luxembourg.lu (new window)">luxembourg.lu</a></li>
                    <li><a className={classes.govbarlink} href="http://guichet.lu" target="_blank" title="guichet.lu (new window)">guichet.lu</a></li>
                    <li><a className={classes.govbarlink} href="http://gouvernement.lu" target="_blank" title="gouvernement.lu (new window)">gouvernement.lu</a></li>
                    <li><a className={`${classes.govbarlink} ${classes.govbarmore}`} href="http://etat.lu/" target="_blank" title="Directory of public Luxembourg websites (new window)">Other sites</a></li>
                </ul>
            </div>
        </>
    );
}
