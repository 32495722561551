import React, { Suspense } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import './i18n.js';
import Loading from './components/Animations/Loading.jsx';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  // For SSR or static pre-rendered HTML
  hydrateRoot(
    rootElement,
    <Suspense fallback={<Loading />}><App /></Suspense>
  );
} else {
  // For regular client-side rendering
  createRoot(rootElement).render(
    <Suspense fallback={<Loading />}><App /></Suspense>
  );
}