import TabBar from '../TabBar/TabBar.jsx';
import { useTranslation } from "react-i18next";
import classes from "./Header.module.css";
import logoW from "../../images/logo_w.png";
import { NavLink } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher.jsx';
import { useState } from 'react';
import mobileMenu from '../../images/mobile-menu.png';
import MobileNavigation from '../popups/MobileNavigation.jsx';
import { AnimatePresence } from 'framer-motion';


export default function Header({ onSetPage, isActive }) {
    const { i18n, t } = useTranslation(["General"]);
    const currentLanguage = i18n.language;
    const [isMobileNavVisible, setMobileNavVisible] = useState(false);
    const menuItems = [

        {
            path: `/${currentLanguage}/exhibition`,
            label: t('Exhibition')
        },
        {
            path: `/${currentLanguage}/material`,
            label: t('Material')
        },
        {
            path: `/${currentLanguage}/library`,
            label: t('Library')
        }
    ];
    function closeMobileNav() {
        setMobileNavVisible(false);
    }
    function openMobileNav() {
        setMobileNavVisible(true);
    }

    return (
        <>
            <header className={classes.header}>
                <img className='mobile-only' src={mobileMenu} alt='Menu' onClick={openMobileNav} />
                <NavLink className="desktop-only" to={`/${currentLanguage}`}><img className={classes.logo} src={logoW} alt='Wüüsten' /></NavLink>
                <TabBar className="desktop-only" menuItems={menuItems} isActive={isActive}></TabBar>
                <LanguageSwitcher />
            </header>
            <AnimatePresence>
                {isMobileNavVisible && <MobileNavigation className='mobile-only' menuItems={menuItems} isActive={isActive} closeMobileNav={closeMobileNav} />}
            </AnimatePresence>

        </>
    );
}