import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient();

export async function fetchData(url) {
    try {
        const response = await fetch(url);
        const resData = await response.json();
        if (!response.ok) {
            throw new Error('Failed to fetch data.');
        }
        return resData;
    } catch (error) {
        return { isError: true, message: error.message };  // Return error as part of the data
    }
}