import classes from "./Block.module.css";
import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import React from 'react';

export default function Block({ children, title, animated = 1, scheme = 'light', className, ...props }) {
    const { t } = useTranslation("General");

    const { ref, inView } = useInView({
        triggerOnce: false, // Animation triggers only once
        rootMargin: '-100px 0px',  // Trigger when the element is within 100px from the top of the viewport
    });

    let content = <div {...props}>
        <div className={classes.blockContent}>
            {title && <h2
                className={classes.blockTitle}
                style={scheme === 'dark' ? { color: 'white' } : {}}
            >{t(title)}</h2>}
            {children}
        </div>
    </div>;


    return (
        <>{animated == 1 && <motion.div
            ref={ref}
            className={`${classes.block} ${className}`}
            style={scheme !== 'dark' ? { backgroundColor: 'white' } : {}}
            initial={{ opacity: 0, y: 50 }} // Start offscreen (50px down, invisible)
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Animate to visible when in view
            transition={{ duration: 0.8, ease: 'easeOut' }} // Customize the animation
        >
            {content}
        </motion.div>}
            {animated !== 1 && <div
                className={`${classes.block} ${className}`}
                style={scheme !== 'dark' ? { backgroundColor: 'white' } : {}}>

                {content}
            </ div >}

        </>
    );
}
