import BackgroundImage from "./BackgroundImage.jsx";
import backgroundHome from "../../images/background-front.jpg";
import backgroundExpo from "../../images/background-expo.jpg";
import backgroundMaterial from "../../images/background-material.jpg";
import backgroundLibrary from "../../images/background-books.jpg";
import React from 'react';

const backgrounds = {
        home: backgroundHome,
        exhibition: backgroundExpo,
        library: backgroundLibrary,
        material: backgroundMaterial,
};

export default function BackgroundSwitcher({ activePage }) {
        const imageToShow = backgrounds[activePage]; // Determine the image based on the active page

        return (
                <div className="background-gradient">
                        <BackgroundImage image={imageToShow} isVisible={!!imageToShow} />
                </div>

        );
};
