import { Outlet } from "react-router-dom";
import Header from '../components/Header/Header.jsx';
import Footer from '../components/Footer/Footer.jsx';
import GovBarBlock from "./Block/GovBarBlock.js";
import React from 'react';

function RootLayout() {


    return (
        <>
            <GovBarBlock />
            <Header />
            <Outlet />
            <Footer />

        </>

    );
}

export default RootLayout;