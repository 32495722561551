import { useTranslation } from "react-i18next";
import i18next from "i18next";
import classes from "./Footer.module.css";
import logoDeloitte from "../../images/logo_deloitte.png";
import logoScript from "../../images/logo_script.png";
import React from 'react';


export default function Header({ onSetPage, isActive }) {
    const { i18n, t } = useTranslation(["General"]);


    return (
        <footer className={classes.footer}>
            <div>© 2024 SCRIPT | All rights reserved | <a href="https://script.lu/fr/mentions-legales" target="_blank">Mentions légales</a></div>
            <div>
                <ul className={classes.logos}>
                    <li><a href="https://deloitte.lu" target="_blank"><img src={logoDeloitte} /></a>
                    </li>
                    <li><a href="https://script.lu" target="_blank"><img src={logoScript} /></a>
                    </li>
                </ul>
            </div>
        </footer>
    );
}